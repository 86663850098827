import React, { useState } from "react";
import { Link } from "gatsby";

const BukuProdi = () => {
  const [password, setPassword] = useState("");
  const [accessGranted, setAccessGranted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const checkPassword = async () => {
    const response = await fetch('/api/validatePassword', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password }),
    });
  
    const data = await response.json();
    if (data.valid) {
      setAccessGranted(true);
    } else {
      alert('Password Salah!');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      checkPassword();
    }
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const inputContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
    backgroundColor: '#f2f2f2',
    fontFamily: 'Arial, sans-serif',
  };

  const inputStyle = {
    margin: '10px',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ddd',
  };

  const buttonStyle = {
    margin: '10px',
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '5px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
  };

  if (!accessGranted) {
    return (
        <div>
            <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
                <div className="flex items-center text-2xl">
                    <Link to="/">
                        <img src="../../../kabimaLogoNew.png" alt="Kabima Logo" height={80} width={80}/>
                    </Link>
                </div>
                <div className="flex-grow text-center sm:text-right">
                    <h1 className="text-xl font-semibold">Buku Prodi</h1>
                </div>
            </div>

            <div style={inputContainerStyle}>
                <input
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={e => setPassword(e.target.value)}
                onKeyPress={handleKeyPress}
                style={inputStyle}
                placeholder="Enter Password"
                />
                <button onClick={checkPassword} style={buttonStyle}>Enter</button>
                <button onClick={togglePassword} style={buttonStyle}>
                {showPassword ? 'Hide' : 'Show'} Password
                </button>
            </div>
        </div>
    );
  }

  return <div style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
    <iframe
        style={{ width: '100%', height: '100%', border: 'none' }}
        src={`https://online.anyflip.com/mqzdh/zaer/index.html`}
        seamless="seamless"
        scrolling="no"
        frameBorder="0"
        allowTransparency="true"
        allowFullScreen="true"
      ></iframe>
  </div>;
};

export default BukuProdi;
